// src/components/dashboard/SubscriptionPage.js
import React, { useState } from 'react';
import { Package, Edit, Plus, Check, ArrowUp, MoreVertical } from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { subscriptionAPI } from '../../services/api';

const SubscriptionPage = () => {
  const [subscriptionTiers] = useState([
    {
      id: 1,
      name: 'Trial',
      price: 'Free',
      users: '5 users',
      storage: '10GB',
      features: ['Basic testing', 'Email support', '5 concurrent tests'],
      active_tenants: 15
    },
    {
      id: 2,
      name: 'Standard',
      price: '$99/mo',
      users: '20 users',
      storage: '100GB',
      features: ['Advanced testing', 'Priority support', '20 concurrent tests'],
      active_tenants: 22
    },
    {
      id: 3,
      name: 'Enterprise',
      price: 'Custom',
      users: 'Unlimited',
      storage: 'Unlimited',
      features: ['Custom testing', '24/7 support', 'Unlimited tests'],
      active_tenants: 5
    }
  ]);

  const subscriptionStats = {
    totalRevenue: '$128,450',
    activeSubscriptions: 42,
    avgRevenuePerTenant: '$3,058'
  };

  const tierDistributionData = [
    { name: 'Trial', value: 15 },
    { name: 'Standard', value: 22 },
    { name: 'Enterprise', value: 5 }
  ];

  const recentActivity = [
    {
      id: 1,
      type: 'upgrade',
      company: 'TechCorp',
      from: 'Standard',
      to: 'Enterprise',
      time: '2 hours ago'
    },
    {
      id: 2,
      type: 'new',
      company: 'DevCo',
      plan: 'Standard',
      time: '1 day ago'
    }
  ];

  const handleEditTier = (tierId) => {
    // Implement edit functionality
    console.log('Edit tier:', tierId);
  };

  return (
    <div className="p-6 space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Subscription Management</h1>
        <button className="flex items-center space-x-2 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700">
          <Plus className="h-4 w-4" />
          <span>Add Plan</span>
        </button>
      </div>

      {/* Stats */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {Object.entries(subscriptionStats).map(([key, value]) => (
          <div key={key} className="bg-white p-6 rounded-lg shadow">
            <p className="text-gray-600 text-sm">{key.replace(/([A-Z])/g, ' $1').trim()}</p>
            <p className="text-2xl font-bold mt-2">{value}</p>
          </div>
        ))}
      </div>

      {/* Distribution Chart */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-lg font-semibold mb-4">Subscription Distribution</h2>
        <div className="h-64">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={tierDistributionData}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="#3B82F6" label={{ position: 'top' }} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Subscription Tiers */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {subscriptionTiers.map((tier) => (
          <div key={tier.id} className="bg-white p-6 rounded-lg shadow">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">{tier.name}</h3>
              <button 
                onClick={() => handleEditTier(tier.id)}
                className="text-blue-600 hover:text-blue-800"
              >
                <Edit className="h-4 w-4" />
              </button>
            </div>
            <div className="mb-4">
              <p className="text-2xl font-bold">{tier.price}</p>
              <p className="text-sm text-gray-600">{tier.users} • {tier.storage} storage</p>
            </div>
            <div className="space-y-3 mb-4">
              {tier.features.map((feature, index) => (
                <div key={index} className="flex items-center text-sm">
                  <Check className="h-4 w-4 text-green-500 mr-2" />
                  <span>{feature}</span>
                </div>
              ))}
            </div>
            <div className="pt-4 border-t">
              <div className="flex justify-between text-sm">
                <span className="text-gray-600">Active Tenants</span>
                <span className="font-semibold">{tier.active_tenants}</span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Recent Activity */}
      <div className="bg-white rounded-lg shadow">
        <div className="p-4 border-b">
          <h2 className="text-lg font-semibold">Recent Activity</h2>
        </div>
        <div className="divide-y divide-gray-200">
          {recentActivity.map((activity) => (
            <div key={activity.id} className="p-4 flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <div className={`h-10 w-10 rounded-full flex items-center justify-center ${
                  activity.type === 'upgrade' ? 'bg-green-100' : 'bg-blue-100'
                }`}>
                  {activity.type === 'upgrade' ? (
                    <ArrowUp className={`h-5 w-5 ${
                      activity.type === 'upgrade' ? 'text-green-600' : 'text-blue-600'
                    }`} />
                  ) : (
                    <Package className="h-5 w-5 text-blue-600" />
                  )}
                </div>
                <div>
                  <p className="font-medium">
                    {activity.type === 'upgrade' 
                      ? `${activity.company} upgraded from ${activity.from} to ${activity.to}`
                      : `${activity.company} subscribed to ${activity.plan} plan`
                    }
                  </p>
                  <p className="text-sm text-gray-500">{activity.time}</p>
                </div>
              </div>
              <button className="text-gray-400 hover:text-gray-600">
                <MoreVertical className="h-5 w-5" />
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Export Section */}
      <div className="bg-white p-4 rounded-lg shadow">
        <div className="flex justify-between items-center">
          <div>
            <h3 className="font-semibold">Export Subscription Data</h3>
            <p className="text-sm text-gray-600">Download detailed reports and analytics</p>
          </div>
          <button className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200">
            Export to CSV
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPage;