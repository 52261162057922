import axios from 'axios';

const BASE_URL = 'http://localhost:8000/api/v1';

// Create axios instance with default config
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add token to requests
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// API endpoints
export const authAPI = {
  login: (credentials) => api.post('/auth/login', credentials),
  logout: () => api.post('/auth/logout'),
  getMe: () => api.get('/auth/me'),
};

export const usersAPI = {
  getUsers: () => api.get('/admin/users'),
  createUser: (userData) => api.post('/admin/users', userData),
  updateUser: (userId, userData) => api.put(`/admin/users/${userId}`, userData),
  deleteUser: (userId) => api.delete(`/admin/users/${userId}`),
};

export const tenantsAPI = {
  getTenants: () => api.get('/admin/tenants'),
  createTenant: (tenantData) => api.post('/admin/tenants', tenantData),
  updateTenant: (tenantId, tenantData) => api.put(`/admin/tenants/${tenantId}`, tenantData),
};

export const subscriptionAPI = {
  getTiers: () => api.get('/admin/subscription-tiers'),
  createTier: (tierData) => api.post('/admin/subscription-tiers', tierData),
  updateTier: (tierName, tierData) => api.put(`/admin/subscription-tiers/${tierName}`, tierData),
};

export default api;