// src/components/dashboard/TenantsPage.js
import React, { useState } from 'react';
import { Building, Edit, Plus, Search, Filter, Download, MoreVertical } from 'lucide-react';
import { tenantsAPI } from '../../services/api';

const TenantsPage = () => {
  const [tenants] = useState([
    { 
      id: 1, 
      name: 'Acme Corp', 
      plan: 'Enterprise', 
      users: 150, 
      storage: '2.5TB',
      status: 'Active',
      lastBilling: '2024-10-01'
    },
    { 
      id: 2, 
      name: 'TechCo', 
      plan: 'Standard', 
      users: 45, 
      storage: '750GB',
      status: 'Active',
      lastBilling: '2024-10-15'
    }
  ]);

  const tenantStats = {
    totalTenants: 45,
    activeTenants: 42,
    avgUsersPerTenant: 28
  };

  return (
    <div className="p-6 space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Tenants Management</h1>
        <button className="flex items-center space-x-2 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700">
          <Plus className="h-4 w-4" />
          <span>Add Tenant</span>
        </button>
      </div>

      {/* Stats */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {Object.entries(tenantStats).map(([key, value]) => (
          <div key={key} className="bg-white p-6 rounded-lg shadow">
            <p className="text-gray-600 text-sm">{key.replace(/([A-Z])/g, ' $1').trim()}</p>
            <p className="text-2xl font-bold mt-2">{value}</p>
          </div>
        ))}
      </div>

      {/* Tenants Table */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="p-4 border-b flex items-center justify-between">
          <div className="relative">
            <input
              type="text"
              placeholder="Search tenants..."
              className="pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <Search className="absolute left-3 top-2.5 text-gray-400 h-5 w-5" />
          </div>
          <div className="flex space-x-2">
            <button className="p-2 hover:bg-gray-100 rounded-lg">
              <Filter className="h-5 w-5 text-gray-600" />
            </button>
            <button className="p-2 hover:bg-gray-100 rounded-lg">
              <Download className="h-5 w-5 text-gray-600" />
            </button>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Plan</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Users</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Storage</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Billing</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {tenants.map((tenant) => (
                <tr key={tenant.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap font-medium">{tenant.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{tenant.plan}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{tenant.users}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{tenant.storage}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 py-1 rounded-full text-xs ${
                      tenant.status === 'Active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                    }`}>
                      {tenant.status}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{tenant.lastBilling}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-right">
                    <button className="text-blue-600 hover:text-blue-800 mx-2">
                      <Edit className="h-4 w-4" />
                    </button>
                    <button className="text-gray-600 hover:text-gray-800 mx-2">
                      <MoreVertical className="h-4 w-4" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TenantsPage;