import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  Users, Building, Package, Activity, 
  ArrowUp, ArrowDown, DollarSign, AlertCircle,
  Smartphone, TabletSmartphone, Monitor, Wifi,
  WifiOff, Phone, Tablet
} from 'lucide-react';
import { LineChart, Line, BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';


const AdminDashboard = () => {
  // Overview stats including new total devices
  const overviewStats = {
    totalUsers: {
      value: 1234,
      change: '+12.5%',
      trend: 'up'
    },
    activeUsers: {
      value: 890,
      change: '+5.2%',
      trend: 'up'
    },
    totalTenants: {
      value: 45,
      change: '-2.1%',
      trend: 'down'
    },
    revenue: {
      value: '$128,450',
      change: '+15.3%',
      trend: 'up'
    },
    totalDevices: {
      value: 567,
      change: '+8.3%',
      trend: 'up'
    }
  };

  // User activity data for line chart
  const userActivityData = [
    { date: '2024-01', users: 750 },
    { date: '2024-02', users: 820 },
    { date: '2024-03', users: 880 },
    { date: '2024-04', users: 890 },
    { date: '2024-05', users: 950 },
    { date: '2024-06', users: 1020 }
  ];

  // Subscription distribution data for pie chart
  const subscriptionData = [
    { name: 'Trial', value: 30 },
    { name: 'Standard', value: 45 },
    { name: 'Enterprise', value: 25 }
  ];

  // Device distribution data
  const deviceDistributionData = [
    { name: 'Android Online', value: 180, category: 'Android', status: 'Online' },
    { name: 'Android Offline', value: 120, category: 'Android', status: 'Offline' },
    { name: 'iOS Online', value: 160, category: 'iOS', status: 'Online' },
    { name: 'iOS Offline', value: 107, category: 'iOS', status: 'Offline' }
  ];

  // Device summary calculations
  const deviceSummary = {
    android: {
      total: deviceDistributionData
        .filter(d => d.category === 'Android')
        .reduce((acc, curr) => acc + curr.value, 0),
      online: deviceDistributionData
        .find(d => d.category === 'Android' && d.status === 'Online')?.value || 0
    },
    ios: {
      total: deviceDistributionData
        .filter(d => d.category === 'iOS')
        .reduce((acc, curr) => acc + curr.value, 0),
      online: deviceDistributionData
        .find(d => d.category === 'iOS' && d.status === 'Online')?.value || 0
    },
    total: {
      online: deviceDistributionData
        .filter(d => d.status === 'Online')
        .reduce((acc, curr) => acc + curr.value, 0),
      offline: deviceDistributionData
        .filter(d => d.status === 'Offline')
        .reduce((acc, curr) => acc + curr.value, 0)
    }
  };

  // Recent alerts/notifications
  const recentAlerts = [
    {
      id: 1,
      type: 'success',
      message: 'New enterprise client onboarded',
      time: '2 hours ago'
    },
    {
      id: 2,
      type: 'warning',
      message: 'High usage detected in TechCorp tenant',
      time: '5 hours ago'
    },
    {
      id: 3,
      type: 'info',
      message: 'System maintenance scheduled',
      time: '1 day ago'
    }
  ];

  // Revenue by tenant data for bar chart
  const revenueData = [
    { tenant: 'TechCorp', revenue: 25000 },
    { tenant: 'DevCo', revenue: 18000 },
    { tenant: 'InnoSys', revenue: 15000 },
    { tenant: 'DataTech', revenue: 12000 },
    { tenant: 'CloudNet', revenue: 10000 }
  ];

  // Colors for charts
  const SUBSCRIPTION_COLORS = ['#60A5FA', '#34D399', '#A78BFA'];
  const DEVICE_COLORS = {
    'Android Online': '#34D399',
    'Android Offline': '#6EE7B7',
    'iOS Online': '#60A5FA',
    'iOS Offline': '#93C5FD'
  };

  return (
    <div className="p-6 space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Dashboard Overview</h1>
        <div className="flex space-x-4">
          <select className="border rounded-lg px-3 py-2">
            <option>Last 7 days</option>
            <option>Last 30 days</option>
            <option>Last 90 days</option>
          </select>
          <button className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700">
            Download Report
          </button>
        </div>
      </div>

      {/* Stats Overview */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6">
        {Object.entries(overviewStats).map(([key, data]) => (
          <div key={key} className="bg-white p-6 rounded-lg shadow">
            <div className="flex justify-between items-start">
              <div>
                <p className="text-gray-600 text-sm">{key.replace(/([A-Z])/g, ' $1').trim()}</p>
                <p className="text-2xl font-bold mt-1">{data.value}</p>
              </div>
              <span className={`flex items-center text-sm ${
                data.trend === 'up' ? 'text-green-600' : 'text-red-600'
              }`}>
                {data.trend === 'up' ? (
                  <ArrowUp className="h-4 w-4 mr-1" />
                ) : (
                  <ArrowDown className="h-4 w-4 mr-1" />
                )}
                {data.change}
              </span>
            </div>
          </div>
        ))}
      </div>

     {/* Device Summary Cards */}
<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
  <div className="bg-white p-6 rounded-lg shadow">
    <div className="flex items-center space-x-3">
      <div className="p-3 bg-green-100 rounded-full">
        <Smartphone className="h-6 w-6 text-green-600" />
      </div>
      <div>
        <p className="text-sm text-gray-600">Android Devices</p>
        <p className="text-xl font-bold">{deviceSummary.android.total}</p>
        <p className="text-sm text-green-600">
          {deviceSummary.android.online} Online
        </p>
      </div>
    </div>
  </div>

  <div className="bg-white p-6 rounded-lg shadow">
    <div className="flex items-center space-x-3">
      <div className="p-3 bg-blue-100 rounded-full">
        <TabletSmartphone className="h-6 w-6 text-blue-600" />
      </div>
      <div>
        <p className="text-sm text-gray-600">iOS Devices</p>
        <p className="text-xl font-bold">{deviceSummary.ios.total}</p>
        <p className="text-sm text-blue-600">
          {deviceSummary.ios.online} Online
        </p>
      </div>
    </div>
  </div>

  <div className="bg-white p-6 rounded-lg shadow">
    <div className="flex items-center space-x-3">
      <div className="p-3 bg-emerald-100 rounded-full">
        <Wifi className="h-6 w-6 text-emerald-600" />
      </div>
      <div>
        <p className="text-sm text-gray-600">Online Devices</p>
        <p className="text-xl font-bold">{deviceSummary.total.online}</p>
        <p className="text-sm text-emerald-600">
          Active and Available
        </p>
      </div>
    </div>
  </div>

  <div className="bg-white p-6 rounded-lg shadow">
    <div className="flex items-center space-x-3">
      <div className="p-3 bg-gray-100 rounded-full">
        <WifiOff className="h-6 w-6 text-gray-600" />
      </div>
      <div>
        <p className="text-sm text-gray-600">Offline Devices</p>
        <p className="text-xl font-bold">{deviceSummary.total.offline}</p>
        <p className="text-sm text-gray-600">
          Currently Unavailable
        </p>
      </div>
    </div>
  </div>
</div>

      {/* Charts Section */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* User Activity Chart */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-lg font-semibold mb-4">User Activity</h2>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={userActivityData}>
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Line 
                  type="monotone" 
                  dataKey="users" 
                  stroke="#3B82F6" 
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Device Distribution Chart */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-lg font-semibold mb-4">Device Distribution</h2>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={deviceDistributionData}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, value }) => `${name} (${value})`}
                >
                  {deviceDistributionData.map((entry, index) => (
                    <Cell 
                      key={`cell-${index}`} 
                      fill={DEVICE_COLORS[entry.name]} 
                    />
                  ))}
                </Pie>
                <Tooltip 
                  formatter={(value, name) => [
                    `${value} devices`,
                    name
                  ]}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="mt-4 grid grid-cols-2 gap-2">
            {Object.entries(DEVICE_COLORS).map(([name, color]) => (
              <div key={name} className="flex items-center space-x-2">
                <div 
                  className="w-3 h-3 rounded-full" 
                  style={{ backgroundColor: color }}
                />
                <span className="text-sm text-gray-600">{name}</span>
              </div>
            ))}
          </div>
        </div>

        {/* Subscription Distribution */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-lg font-semibold mb-4">Subscription Distribution</h2>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={subscriptionData}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                >
                  {subscriptionData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={SUBSCRIPTION_COLORS[index % SUBSCRIPTION_COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Revenue by Tenant */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-lg font-semibold mb-4">Top Revenue by Tenant</h2>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={revenueData}>
                <XAxis dataKey="tenant" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="revenue" fill="#3B82F6" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Recent Alerts */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-lg font-semibold mb-4">Recent Alerts</h2>
          <div className="space-y-4">
            {recentAlerts.map((alert) => (
              <div key={alert.id} className="flex items-start space-x-3">
                <div className={`p-2 rounded-full ${
                  alert.type === 'success' ? 'bg-green-100' :
                  alert.type === 'warning' ? 'bg-yellow-100' : 'bg-blue-100'
                }`}>
                  <AlertCircle className={`h-5 w-5 ${
                    alert.type === 'success' ? 'text-green-600' :
                    alert.type === 'warning' ? 'text-yellow-600' : 'text-blue-600'
                  }`} />
                </div>
                <div className="flex-1">
                  <p className="text-sm font-medium">{alert.message}</p>
                  <p className="text-xs text-gray-500">{alert.time}</p>
                  </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Quick Actions */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        <Link to="/users" className="bg-white p-6 rounded-lg shadow hover:shadow-md transition-shadow">
          <div className="flex items-center space-x-3">
            <Users className="h-6 w-6 text-blue-600" />
            <div>
              <h3 className="font-semibold">Manage Users</h3>
              <p className="text-sm text-gray-600">View and manage user accounts</p>
            </div>
          </div>
        </Link>

        <Link to="/tenants" className="bg-white p-6 rounded-lg shadow hover:shadow-md transition-shadow">
          <div className="flex items-center space-x-3">
            <Building className="h-6 w-6 text-purple-600" />
            <div>
              <h3 className="font-semibold">Manage Tenants</h3>
              <p className="text-sm text-gray-600">Configure tenant settings</p>
            </div>
          </div>
        </Link>

        <Link to="/devices" className="bg-white p-6 rounded-lg shadow hover:shadow-md transition-shadow">
          <div className="flex items-center space-x-3">
            <Smartphone className="h-6 w-6 text-green-600" />
            <div>
              <h3 className="font-semibold">Device Management</h3>
              <p className="text-sm text-gray-600">Monitor and manage devices</p>
            </div>
          </div>
        </Link>

        <Link to="/subscriptions" className="bg-white p-6 rounded-lg shadow hover:shadow-md transition-shadow">
          <div className="flex items-center space-x-3">
            <Package className="h-6 w-6 text-amber-600" />
            <div>
              <h3 className="font-semibold">Subscription Plans</h3>
              <p className="text-sm text-gray-600">Manage subscription tiers</p>
            </div>
          </div>
        </Link>
      </div>

      {/* Device Activity Log */}
      <div className="bg-white p-6 rounded-lg shadow">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Recent Device Activity</h2>
          <Link to="/device-logs" className="text-blue-600 hover:text-blue-700 text-sm">
            View All Logs
          </Link>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead>
              <tr className="bg-gray-50">
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Device
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Action
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  User
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Time
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {[
                {
                  device: 'iPhone 13 Pro',
                  action: 'Test Session Started',
                  user: 'John Doe',
                  time: '5 min ago',
                  status: 'active'
                },
                {
                  device: 'Samsung Galaxy S21',
                  action: 'Device Disconnected',
                  user: 'Jane Smith',
                  time: '15 min ago',
                  status: 'error'
                },
                {
                  device: 'Google Pixel 6',
                  action: 'Test Session Completed',
                  user: 'Mike Johnson',
                  time: '1 hour ago',
                  status: 'completed'
                }
              ].map((log, idx) => (
                <tr key={idx}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {log.device}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {log.action}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {log.user}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {log.time}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full 
                      ${log.status === 'active' ? 'bg-green-100 text-green-800' : 
                        log.status === 'error' ? 'bg-red-100 text-red-800' : 
                        'bg-blue-100 text-blue-800'}`}>
                      {log.status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;