// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import AdminDashboard from './components/dashboard/AdminDashboard';
import UsersPage from './components/dashboard/UsersPage';
import TenantsPage from './components/dashboard/TenantsPage';
import SubscriptionPage from './components/dashboard/SubscriptionPage';
import Navigation from './components/layout/Navigation';

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-gray-50">
        <Navigation />
        <Routes>
          <Route path="/" element={<AdminDashboard />} />
          <Route path="/users" element={<UsersPage />} />
          <Route path="/tenants" element={<TenantsPage />} />
          <Route path="/subscriptions" element={<SubscriptionPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;